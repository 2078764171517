html,
body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.ant-layout {
    background: transparent !important;
}

.ant-row-rtl .logo {
    float: right;
    margin: 16px 0 16px 24px;
}


.footer-bar {
    box-shadow: #e1e1e1 0px -2px 3px 0px;
    background: white !important;
    text-align: right;
    z-index: 5;
    padding: 16px;
}

.content {
    margin-top: 64px;
    margin-left: 2px !important;
    margin-right: 2px !important;
    /*justify-content: center;*/
    /*display: flex;*/
    min-height: calc(100vh - 140px) !important;
    /*background: url("https://www.hicargo.com/wp-content/uploads/2019/07/Ocean3_b13029e7aaaff20c64b13ef28a14f0e8.jpg") no-repeat center center;*/
    /*background-size: auto 100%;*/
    /*filter: blur(8px);*/
}

.small-calendar {
    width: 250px;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
}

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #e6d8d8;
    border-radius: 4px;
}

.ant-btn {
    border-radius: 99px !important;
}

.ant-tag {
    border-radius: 99px !important;
}

.ant-layout-sider-zero-width-trigger {
    background: #096dd985 !important;
    top: -10px !important;
}

.ant-modal-content img {
    max-width: 100%;
}

.ant-table-row-expand-icon{
    float: none !important;
    vertical-align: middle !important;
    transform: scale(1.1) !important;
    background: #faad14 !important;
    color: white !important;
    border-radius: 5px !important;
}

.consignee-table .ant-table-container{
    background: #ffffff;
    /* margin-left: -80px; */
}