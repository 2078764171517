@media print
{
    @page {
        size: letter;
        margin: .25in;
        font-family: Calibri, serif;
    }
}

.bill-form {
    background: white;
    font-family: Calibri, serif;
    page-break-after: avoid;
    page-break-before: always;
    font-size: 10pt;
}

.logo {
    width: 80pt;
    height: 80pt;
    text-align: left;
}

.col-info span {
    float: right;
    align-self: center;
    font-size: 12pt;
}

.cell1 {
    background: #e9e8e8;
}

.cell2 {
    background: white;
}


.bill-form td, .bill-form th {
    border: 1px solid black;
    padding: 4px;
}

.col1 {
    width: 12%;
}

.col2 {
    width: 38%;
}

.col20 {
    width: 20%;
}

.highlighted-row {
    background: #efefef;
}

.line{
    height: 18pt;
    width: 100%;
    line-height: 22pt;
    font-size: 13pt;
}

.underline{
    height: 20pt;
    border-bottom: 1px dotted #cdcdcd;
    width: 100%;
    line-height: 22pt;
    display: flex;
}
.underline > div{
    font-size: 13pt;
    line-height: 21pt;
}


.bill-form:first-child {
    page-break-before: avoid;
}

.stamp{
    position: relative;
    float: right;
    margin-top: 56pt;
    margin-right: 32pt;
    display: inline-block;
    border-radius: 5px;
    border-width: 2px;
    border-color: #ef3e42;
    border-style: solid;
    color: #ef3e42;
    text-transform: uppercase;
    font-size: 16pt;
    font-style: normal;
    font-weight: 900;
    letter-spacing: 1px;
    padding: 0 12px;
    -ms-transform: rotate(-30deg);
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
    opacity: .6;
    -webkit-filter: blur(1px);
    filter: blur(1px);
}