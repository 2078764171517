.from-wrapper{
    margin: auto !important;
    margin-top: 54px !important;
}
.edit-info{
    border-radius: 10px;
    /*box-shadow: 2px 0 35px -3px rgba(0,69,66,.16);*/
    background-color: #fff;
    border: 1px solid #d1d5da;
    padding: 24px 24px 0 24px !important;
    margin: auto !important;
    margin-top: 24px !important;
}