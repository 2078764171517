.dashboard {
    margin: 10px !important;
}

.shipping-table {
    /*margin-top: 10px;*/
    /*border-radius: 10px;*/
    /*box-shadow: 2px 0 35px -3px rgba(0,69,66,.16);*/
    /*background-color: #ccc;*/
    /*border: 1px solid #d1d5da;*/
    transition: 0.3s;
    /*padding: 24px !important;*/
}

.table-detail {
    margin-left: 14px;
}


.row-total-amount {
    color: #e96767;
    font-weight: bold;
    font-size: 15px;
}


.add-shipping {
    border-radius: 5px !important;
}

.create-shipping-wrapper {
    border-radius: 10px;
    box-shadow: 2px 0 35px -3px rgba(0, 69, 66, .16);
    background-color: #fff;
    border: 1px solid #d1d5da;
    transition: 0.3s;
    padding: 24px 24px 0 24px !important;
    width: 600px;
    margin: 24px auto !important;
}
.admin-dashboard {
    width: 100%;
    padding: 8px;
}