.post-view {
    width: 94%;
}

.post-wrapper {
    /*padding: 4px;*/
    margin: 24px 0;
    transition: .2s;
    box-shadow: 1px 0 4px -1px #b3b3b3;
    border-radius: 10px;
    max-height: 200px;
    overflow: hidden;
}

.post-wrapper:hover {
    box-shadow: 1px 0 10px -1px #e68d2e;
}

.cover {
    object-fit: cover;
    border: 0 none;
    display: inline-block;
    width: 100%;
    height: 192px;
    border-radius: 10px;
    vertical-align: middle;
}

.short-detail {
    margin-left: 8px;
}

.title {
    font-size: 22px;
    font-weight: bold;
}

.short_title {
    font-size: 22px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.time {
    color: #a2a2a2;
    font-size: 13px;
}

.short-desc {
    margin-top: 4px;
    font-size: 15px;
    display: block;
    /* display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 145px; */
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .post-wrapper {
        max-height: 98px;
    }

    .short_title {
        font-size: 12px;
    }

    .time {
        font-size: 11px;
    }

    .short-desc {
        font-size: 12px;
        height: 34px;
    }

    .cover {
        width: 100%;
        height: 98px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .short_title {
        font-size: 14px;
    }

    .short-desc {
        font-size: 14px;
        /* -webkit-line-clamp: 2;
        height: 38px; */
    }

    .cover {
        width: 100%;
        height: 100px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .short_title {
        font-size: 15px;
    }
    .post-wrapper {
        max-height: 120px;
    }
    .short-desc {
        font-size: 15px;
        /* -webkit-line-clamp: 2;
        height: 48px; */
    }

    .cover {
        height: 120px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .post-wrapper {
        max-height: 120px;
    }
    .short_title {
        font-size: 16px;
    }

    .short-desc {
        font-size: 15px;
        /* -webkit-line-clamp: 3;
        height: 45px; */
    }

    .cover {
        height: 120px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .post-wrapper {
        max-height: 200px;
    }
    .short_title {
        font-size: 20px;
        -webkit-line-clamp: 3;
    }

    .short-desc {
        font-size: 15px;
        /* -webkit-line-clamp: 6;
        height: 142px; */
    }

    .cover {
        height: 200px;
    }
}