.notice {
    z-index: 10;
    height: 50px;
    width: 95%;
    display: flex;
    align-items: center;
    align-self: center;
    margin: auto;
}

.notice.mobile{
    display: none;
}

.close-notice{
    font-weight: bold;
    background-color: #e8872c;
    border-radius: 15px;
    height: 24px;
    width: 24px;
    text-align: center;
    color: white;
    line-height: 24px;
}

.close-notice:hover{
    box-shadow: #e8872c 0px 0px 2px 1px;
    cursor: pointer;
}

.notice .marquee{
    height: 50px;
}

.notice p{
    margin-top: revert;
}



@media (max-width: 600px) { 
    .notice {   
        display: none;
    }
    .notice.mobile{
        display: flex;
    }
}

.notice.hide{
    display: none;
}