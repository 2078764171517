.form-wrapper{
    margin: auto !important;
    margin-top: 54px !important;
}
.change-pass-form {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #d1d5da;
    padding: 24px 24px 0 24px !important;
    margin: auto !important;
    margin-top: 56px !important;
}