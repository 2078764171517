.login-form {
    background-color: #fff;
    padding: 24px 24px 0 24px !important;
}
.login-form-forgot {
    float: right;
}
.ant-col-rtl .login-form-forgot {
    float: left;
}
.login-form-button {
    width: 100%;
}

.logo2{
    width: 180px;
}

.x0.x1.x2{
    width: 100% !important;
}