.branch-wrapper{
    /*margin: auto !important;*/
    margin-top: 24px !important;
}
.add-branch-form {
    border-radius: 10px;
    box-shadow: 2px 0 35px -3px rgba(0, 69, 66, .16);
    background-color: #fff;
    border: 1px solid #d1d5da;
    transition: 0.3s;
    width: 500px;
    padding: 24px 24px 0 24px !important;
    margin-top: 24px !important;
}