.register-wrapper{
    margin: auto !important;
}
.register-form {
    /*border-radius: 10px;*/
    /*box-shadow: 2px 0 35px -3px rgba(0, 69, 66, .16);*/
    background-color: #fff;
    /*border: 1px solid #d1d5da;*/
    /*min-width: 500px;*/
    padding: 24px 24px 0 24px !important;
    margin-top: 8px !important;
}
